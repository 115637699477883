<template>
    <div class="return-ratio">
        <el-card>
            <div slot="header" class="clearfix">
                <span>收益回撤比</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：计算过去每承担一份回撤所获得的收益。</p>
                <p>
                    如何使用：指标描述了该账户收益是否稳健和具有可持续性。一般来说，统计周期超过一年，收益回撤比大于3，那么恭喜你，投资水平已属于优秀行列；如收益回撤比超过5，则属于非常优秀。通过收益回撤比，可快速预估出在账户承担多少的最大回撤情况下，可获取多少的预期收益，以及账户应当使用的杠杆倍数。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
    //收益回撤比
    import HChart from '@/components/HChart.vue'
    import DateRange from '@/components/DateRange.vue'
    import {Loading} from "element-ui";
    import {analysisData} from "../../utils/api";

    export default {
        name: "return-ratio",
        data() {
            return {
                starLevel: 4,
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        backgroundColor: null,
                        plotBackgroundColor: null
                    },
                    title: false,
                    colors: ['#EB3E41'],
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    xAxis: {
                        tickInterval: 20,
                        categories: []
                    },
                    yAxis: {
                        title: false,
                    },
                    series: [],
                }
            };
        },
        components: {
            HChart,
            DateRange
        },
        methods: {
            getData() {
                if (this.$store.state.curAccountId.length === 0) {
                    return false;
                }
                let loadingInstance = Loading.service({'target': '.h-chart'});
                let accountId = JSON.stringify(this.$store.state.curAccountId);
                let dateRange = JSON.parse(this.$store.state.dateRange);
                analysisData({
                    'account': accountId,
                    'type': 'returnRatio',
                    'dateRange': dateRange.range,
                    'analysisType': this.$store.state.analysisType
                }).then(response => {
                    let options = JSON.parse(JSON.stringify(this.options));
                    options.xAxis.categories = response.data.categories;
                    options.series = response.data.series;
                    this.options = options;
                    loadingInstance.close()
                });
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style lang="scss">
    .return-ratio {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart{
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
